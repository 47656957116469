import React from "react"
import Seo from "@root/Seo"
import tw, { styled } from "twin.macro"
import parse from "html-react-parser"
import Button from "@elements/Button"

const Section = styled.section`
  ${tw`relative z-10 max-h-screen 2xl:h-screen lg:h-70vw h-auto flex flex-col`}
`
const SectionBackground = styled.span`
  ${tw` absolute w-full h-full grid grid-cols-none md:grid-cols-2`}
  & .white {
    ${tw`bg-white`}
  }
  & .blue {
    ${tw`bg-blue`}
  }
`
const HeadlineWrap = styled.div`
  ${tw`relative z-10 text-black font-ubuntu font-black text-center mt-auto pt-24 px-offset md:px-offsetMd lg:px-16 xl:px-24 flex flex-col items-center`}
`
const DescriptionGrid = styled.div`
  ${tw`relative grid grid-cols-none md:grid-cols-2 mt-c60 md:mt-c48 2xl:mt-c60 md:mb-c96 2xl:mb-c120`}
`
const Description = styled.div`
  ${tw`text-white relative z-10 pt-6 pb-10 font-normal md:col-start-2 lg:w-3/4 text-center px-8 sm:px-12 flex flex-col items-center`}
`

const NotFoundPage = () => {
  return (
    <>
      <Seo title="404: Not found" />
      <Section>
        <SectionBackground>
          <div className="white"></div>
          <div className="blue"></div>
        </SectionBackground>
        <HeadlineWrap>
          <h1 className="font-ubuntu font-bold text-52special sm:text-64special md:text-72special 2xl:text-80special relative">
            <span className="font-firacode text-5xl text-blue absolute transform top-8 -left-10 -translate-y-1/2 -translate-x-full">
              {parse(`]|{}->`)}
            </span>
            <span className="relative uppercase inline-block text-center">
              Die Seite finden
              <br />
              wir leider nicht
              <br />
              mehr
            </span>
          </h1>
        </HeadlineWrap>

        <DescriptionGrid>
          <Description className="copy">
            {parse(
              `->> Tut uns leid.<==<br />||=<< Aber wir haben noch andere Seiten >>=||<br />{ mit spannenden Inhalten }<br />|-<<- Schauen Sie sich um! ();`
            )}

            <Button
              buttonText="Zur home"
              buttonHref="/"
              buttonClasses="bg-yellow text-black mr-auto"
            />
          </Description>
        </DescriptionGrid>
      </Section>
    </>
  )
}

export default NotFoundPage
